.menu-button-icon {
    border:  1px solid rgba(243, 227, 54, 0.272);
    border-radius: 5px;
    padding: 5px;
    margin-right: 7px;
    margin-top: 15px;
}

.menu-button-icon-text {
    font-size: medium;
}

.menu-button-icon:hover {
    background-color: rgba(243, 227, 54, 0.272);
}