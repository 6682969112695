.dark-card-2 {
  background-color: #2c2c2c;
  color: #fff;
  border-radius: 5px; /* Reduced border-radius */
  margin: 5px auto; /* Reduced margin */
  box-shadow: 0 0 5px #000; /* Subtler box-shadow */
  width: 96%; /* Slightly smaller width */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  font-family: Arial, sans-serif;
  height: fit-content;
  font-size: 0.9rem; /* Smaller text size */
}

.body-of-toggle-ventas-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.dark-card:hover {
  background-color: #3c3c3c;
}

.kpi-group {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes items evenly with space between */
  width: 100%;
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  white-space: nowrap; /* Prevents wrapping */
}

.kpi-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 10px; /* Reduced padding */
  flex: 1;
  min-width: 0; /* Allows flex items to shrink */
  margin: 0 5px; /* Reduced margin */
}

.kpi-divider {
  border-right: 1px solid #e4e4e4;
  height: 60%; /* Adjusted height */
  margin: 0 10px; /* Reduced margin */
}

.kpi-title {
  font-weight: bold;
  margin-bottom: 3px; /* Reduced margin */
  font-size: small; /* Smaller font size */
  color: rgb(243, 227, 54);
}

.kpi-value {
  font-size: 1rem; /* Slightly smaller font size */
}

/* Target the ToggleButtonGroup to apply a specific class for smaller buttons */
.toggle-button-small .btn {
  font-size: 0.75rem; /* Smaller font size */
  padding: 0.25rem 0.5rem; /* Smaller padding */
  height: auto; /* Adjust height to fit content */
  border-radius: 0.25rem; /* Smaller border-radius for buttons */
  line-height: 1.2; /* Adjust line height for better alignment */
}

.toggle-button-small .btn .fa-icon {
  font-size: 0.9rem; /* Adjust icon size */
}

/* General styles for the card title and toggle button group */
.title-and-toggle {
  position: relative;
  margin: auto;
  bottom: 15px;
}

.title-and-toggle .card-title {
  color: #ffc107; /* Gold color */
  font-size: 1rem; /* Adjust as needed */
}

/* Styling for the headers and center labels */
.center-header, .center-label {
  color: #ffc107; /* Gold color */
  font-weight: bold; /* Make text bold */
}

/* Ensure spacing and layout for the container, rows, and columns */
.container {
  padding: 0 1rem; /* Adjust padding as needed */
}

.row {
  margin-bottom: 0.5rem; /* Adjust space between rows */
}

.col {
  text-align: center; /* Center align text within columns */
}

/* Style for the Navbar */
.navbar {
  border-bottom: 1px solid #ccc; /* Optional: adds a border at the bottom of the Navbar */
}

/* Style for the date filters */
.form-inline .form-group {
  margin-right: 1rem; /* Space between date fields */
}

.form-inline .form-control {
  max-width: 120px; /* Adjust width of date inputs */
}

/* Style for center headers and labels */
.center-header {
  color: #ffc107; /* Gold color for headers */
  font-weight: bold;
}

.center-label {
  font-weight: bold;
  color: #ffc107; /* Gold color for center labels */
}

/* Style for the divider line */
.divider-line {
  border-top: 2px solid #ffc107; /* Gold color divider */
  margin: 1rem 0; /* Space around the divider */
}


.ventas-date-toggle-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.dark-card-header {
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
}

.ventas-date-toggle-turnover-form {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

.custom-row-form-group {
  display: flex;
  flex-direction: row;
}

.form-label-flat {
  color: #ffc107;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 0 !important;
  display: flex; /* Change to inline-flex to fit the content without wrapping */
  align-items: center;
  white-space: nowrap; /* Prevent the text from wrapping to the next line */
}
