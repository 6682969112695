/* Apply styles to the entire Card component */
.dark-insight-card {
    /* Add your styles here */
    background-color: #242428; /* Example background color */
    color: #fff; /* Example text color */
    border: 1px solid #555; /* Example border color */
    min-width: 200px;
    max-width: 300px;
    margin: 5px;
    font-size: medium;
  }
  
  /* Apply styles to the CardHeader component */
  .dark-insight-card-header {
    /* Add your styles here */
    background-color: #242428; /* Example background color */
    color: #fff; /* Example text color */
    border-bottom: 1px solid #777; /* Example border color */
    font-weight: 600;
  }
  
  /* Apply styles to the Card.Body component */
  .dark-insight-card-body {
    /* Add your styles here */
    background-color: #242428; /* Example background color */
    padding: 20px; /* Example padding */
  }
  
  /* Apply styles to the Card.Title and Card.Text components within Card.Body */
  .dark-insight-card-body .card-title,
  .dark-insight-card-body .card-text {
    /* Add your styles here */
    color: #fff; /* Example text color */
  }
  
  /* Additional styles for the entire Card component */
  .dark-insight-card:hover {
    /* Add your styles for hover state */
    background-color: #555; /* Example background color on hover */
  }
  