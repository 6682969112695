.sales-orders-filter-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 98%;
    margin: auto;
}

.sales-orders-fields-holder {
    flex: 1 1 auto; /* Allows the input groups to flexibly adjust */
    margin-right: 10px; /* Adds some spacing between the input groups */
    min-width: 180px; /* Ensures inputs don't become too small */
}

.sales-orders-filters-button {
    width: auto;
    padding: 0.5rem 1rem; /* Adjusts padding for better touch targets */
    flex: 0 0 auto; /* Prevents the button from stretching */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .sales-orders-fields-holder {
        flex: 1 0 100%; /* Stacks the inputs on smaller screens */
        margin-right: 0;
        margin-bottom: 10px; /* Adds space between stacked inputs */
    }

    .sales-orders-filter-toolbar {
        flex-direction: column;
    }
}

.sales-orders-main-table {
    font-size: medium;
    width: 90%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.sales-order-title {
    margin-right: 0px !important;
    padding: 0px !important;
}

.basic-navbar-nav {
    margin: 0px !important;
}

.txt-med {
    font-size: medium;
}

.sales-orders-navbar {
    padding-top: 0px !important;
}