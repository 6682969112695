.CUBO-analyser-demand-subsidiary-main {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.CUBO-analyser-demand-subsidiary-main-view {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-grow: 1;
}

.subsidiary-button-holder-main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 10px;
    width: 11.5vw;
    border: 1px solid #232323;
    padding-top: 15px;
    height: 85vh;
    overflow-y: auto;
    margin-top: 5px;
}

.subsidiary-button-holder-sub {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: column;
    margin-top: 15px;
}

.CUBO-analyser-demand-subsidiary-selection-box-item {
    display: flex;
    width: auto;
}

.smaller-page-title {
    font-size: 0.9rem;
    margin-top: 10px;
}

.CUBO-DRP-ANALYSER-TABLE {
    display: flex;
    width: 97%;
    margin: auto;
}

.CUBO-analyser-main-view {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-grow: 1;
}