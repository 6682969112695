.debt-portal-customer-account-main-table {
    display: flex;
    flex-direction: column;
    width: 96%;
    margin: auto;
    max-height: 85vh;
    overflow-y: scroll;
}

.actual-debt-table {
    width: 100%;
    margin: auto;
    margin-top: 5px;

}

