/* LookupFieldWithDropdown.css */

.drop-down-list {
    position: relative; /* Ensure the dropdown menu is positioned relative to its container */
    z-index: 1; /* Set a higher z-index value to make the dropdown appear above other elements */
    box-shadow: inset 0 0 0.5px rgba(0, 0, 0, 0.5);
    border: 1px solid #ced4da;
    height: auto;
    list-style-type: none;
    text-align: left;
    font-size: small;
  }

  
  .dropdown-results .dropdown-menu {
    display: block;
  }

  .form-control.stretchable-textarea {
    resize: vertical;
    display: flex;
  }

  .lookup-main-form {
    margin-top: 20px;
  }
  
  .form-control {
    resize: vertical; /* Add this line to make the textarea vertically resizable */
  }

  .lookup-main-card {
    margin-top: 4vh;
    font-size: small;
  }

  .flex-list-row {
    flex-direction: row;
    display: flex !important;
    justify-content: space-evenly;
  }

  .lookup-list-entry-col {
    border-right: 1px solid #021d38;
  }