.CUBO-analyser-demand-main {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.check-holder-CUBO-config {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: auto;
}