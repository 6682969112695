.custom-drop-down-toggle {
    padding: 0px;

}

.navbar-brand-cubo-analyser-demand {
    font-size: 1.0rem;
    font-weight: 500;
    color: rgb(200, 188, 51);
    margin-left: 15px;
    padding: 0px;
}

.-CUBOAnalyerDemand-navbar {
    padding: 0px;
}

.demand-options-dropdown {

}