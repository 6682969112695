.navbar-parent {
    display: flex;
    flex-direction: column;
}


.navbtn-colors {
    overflow-y: scroll;
    height: 50vh;
}

#colours{
    font-size: medium !important;
    margin-left: 15px;
}

#finish {
    margin-left: 25px;
    font-size: medium !important;
}

#thickness {
    margin-left: 25px;
    font-size: medium !important;
}

.applyFilter {
    margin-left: 25px;
    font-size: small !important;
}

.applyFilter:hover {
    background-color: #8bc92e !important;
    border-color: #ccc !important;
    color: rgb(241, 240, 229);
}

.clearFilter {
    margin-left: 25px;
    font-size: small !important;
}

.clearFilter:hover {
    background-color: #d33a3d !important;
    border-color: #ccc !important;
    color: rgb(241, 240, 229);
}

.btn-tools {
    margin-left: 25px;
    position: relative;
    top: -5px;
    font-size: small !important;
}

.btn-tools:hover {
    background-color: #2e76c9 !important;
    border-color: #ccc !important;
    color: rgb(241, 240, 229);
}

.navbar-parent-discounts {
    display: flex;
    flex-direction: column;
}

.nav-discounts-top-container {
    display: flex;
    align-items: center;
    justify-content: left !important;
}