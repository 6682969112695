.form-row-slabs-box {
    display: flex;
    flex-direction: column;
    width: 92%;
    border: 1px solid rgb(99, 93, 24);
    border-radius: 5px;
    margin: auto;
    margin-top: 1vh;
    padding: 1vh;
    font-size: medium;
}

.formGroup {
    display: flex;
    height: fit-content;
    padding: 1vh;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.form-group, .formGroup-comments {
    font-size: medium;
}

.form-row-slabs {
    display: flex;
    align-items: center;
    width: 90%;
    border: 1px solid rgb(99, 93, 24);
    margin: auto;
    margin-top: 1vh;
    padding: 1vh;
    border-radius: 5px;
}

.button {
    margin: auto;
    margin-top: 2vh;
}

@media screen and (max-width: 768px) {
    .form-row-slabs-box {
        width: 90%;
    }
    
}

.form-row-comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    margin-top: 1vh;
    padding: 1vh;
}

.formGroup-comments {
    display: flex;
    height: fit-content;
    min-height: 10vh;
    padding: 1vh;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.files-box {
    border-radius: 5px;
    width: 90%;
    flex-direction: column;
}

.file-upload-text {
    font-size: small;
}


.form-control {
    padding: 0.3 !important;
    font-size: small;
}

.form-label {
    margin-bottom: 0.3 !important;
}