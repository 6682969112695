.data-browser-card {
    width: 80%;
    margin: auto;
    display: block;
    margin-top: 10vh;
    font-size: medium !important;
    background-color: #242428;
    color: rgb(243, 227, 54);
}
    
  .data-browser-card .input-group-text {
    /* Add any specific styling for the input group text */
    min-width: 2vw; /* Adjust the width as needed */
    height: 25px;
    margin-top: 2px;
  }
  
  .data-browser-card input[type="file"] {
    /* Add any specific styling for the file input */
    margin-top: 5px; /* Add spacing between the text and the input */
    width: 30px;
  }
  
  .data-browser-card .file-path {
    /* Add styling for the file path text box */
    margin-top: 5px; /* Add spacing between the file input and the text box */
    padding: 5px;
  }

  .file-input-container {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .file-path {
    min-width: 20vw;
    max-width: 40vw;
    bottom: 3px;
    position: relative;
    height: 25px;
  }

  .file-input-button {
    padding-left: 50px;
    position: relative;
    padding-top: 2px;
    height: 25px;
  }

  .form-switch {
    width: 200px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .custom-number-input-budget {
    height: 25px;
    margin-top: 2px;
    margin-bottom: 10px;
  }

  .custom-input-group-budget {
    width: 50%;
    margin: auto;
  }

  #basic-addon1 {
    width: 120px;
  }

  .file-input-button:hover {
    cursor: pointer;
    background-color: orangered;
  }

