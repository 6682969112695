/* INSIGHT STOCK BROWSER  */

.center {
    margin: auto;
}

.MarginTop {
    margin-top: 15px;
}

.overflowY {
    overflow-y: scroll;
}

.INSIGHTSTOCKBROWSER {
    display: flex;
    width: inherit;
}

.stockbrowser {
    height: 70vh;
}

.stock-browser-card {
    height: 60vh;
    overflow-y: scroll;
}

.stock-insight-accordion {
    width: 90%;
    margin: auto;
    padding: 2px;
    font-size: small !important;
    background-color: #242428 !important;
}

.stockbrowser-accordion-item {
    background-color: #242428;
    color: white;
    border: 1px solid #555;
}

.insight-accordion-header {
    background-color: #242428 !important;
    color: white;
    border: 1px solid #555 !important;
}

.accordion-button {
    background-color: #373739 !important;
    color: white !important;
    border: 1px solid #555;
}

.insight-accordion-body {
    background-color: #373739 !important;
    color: white !important;
    border: 1px solid #555;
}

.Stock-Browser-Nav-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.colour-search {
    height: 30px;
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 3px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    font-size: medium;
    align-items: center;
    width: 70%;
}

.searchColourLabel {
    margin-top: 3px;
}

.stock-checker-card {
    width: 90lvw;
}

tr:hover {
    cursor: pointer;

}

td:hover {
    cursor: pointer;
}

.col-50 {
    width: 50%;
}

.col-70 {
    width: 50%;
}

.col-30 {
    width: 30%;
}

@media screen and (max-width: 600px) {
    .flex-row {
        flex-direction: row;
        overflow-x: scroll;
        z-index: 0;
        font-size: x-small;
    }
    
    span {
        font-size: xx-small !important;
    }
}