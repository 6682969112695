.home-main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 90vh;
    min-height: 30px;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeInSecond {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.home-first-section {
    opacity: 0; /* Start with transparency */
    animation: fadeIn 2s ease-in-out forwards; /* Animation properties */
    margin: 2vh;
    font-size: xx-large;
    font-weight: 700;
}

.home-second-section {
    opacity: 0; /* Start with transparency */
    animation: fadeInSecond 2s ease-in-out forwards; /* Animation properties */
    animation-delay: 1s; /* Delay the start of the second animation by 2 seconds */
    margin: 2vh;
    font-size: smaller;
}