.dark-card {
  background-color: #2c2c2c;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  margin-top: 5px;
  box-shadow: 0 0 10px #000;
  width: 90%;
  height: 95%;
}

.dark-card:hover {
  background-color: #3c3c3c;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #000;
}

.excel-dash-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.excel-dash-card {
  font-size: 1rem;
  flex-grow: 1;
}

.nav-bar-right {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  width: fit-content !important;
}

.nav-bar-center {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 50%;
}

.flex-text {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 20px;
}

.flex-text-test {
  display: flex !important;
  flex-direction: row !important;
  margin-left: 10px !important;
}

/* For ExcelDashNavBarFilters to decrease width of dropdowns */
.narrow {
  max-width: 200px !important;
  flex-grow: 0 !important;
}

.MarginTop {
  margin-top: 15px;
}


.-EXCEL-DRP-SUMMARY-TABLE- {
  display: flex;
  overflow-y: scroll;
  max-height: 78vh;
}

.card-small-text {
  font-size: 0.8rem;
}

.-Delivery-Schedule- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}