.plan-it-dash-insight-card{
  display: flex;
  background-color: #2c2c2c;
  color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 15px;
  box-shadow: 0 0 10px #000;
  width: fit-content;
  height: fit-content;
  }
  
.plan-it-dash-insight-card:hover {
  background-color: #3c3c3c;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #000;
}

.plan-it-dash-insight-card-header {
  font-size: small;
  padding: 0 !important;
  padding-top: 4px !important;
}

.plan-it-insight-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 !important;
}

.plan-it-dash-card-icon {
  font-size: 1rem;
}

.plan-it-dash-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 20px;
}

.plan-it-dash-row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 88%;
  margin: auto;
  margin-bottom: 10px;
}

.plan-it-table-body-row,
.plan-it-table-body-cell {
  padding: 0.01rem !important;
  align-content: center;
}

.checkbox-area {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: space-evenly;
  align-content: center;
}

.checkbox-input {
  margin-left: 20px;
}

.qty-adjust-icon {
  font-weight: 800;
  margin: 7px;
  cursor: pointer;
}

.scrollable-table {
  font-size: .85rem;
  width: 90%;
  margin: auto;
}

.sticky-row {
  position: sticky;
  top: 0;
  z-index: 2;
}

.-filter-bar- {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.form-control-modal {
  margin-bottom: 15px;
}