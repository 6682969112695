/* FeedbackForm.css */
.feedback-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 2vh;
    padding: 20px;
    border: 1px solid rgb(99, 93, 24);
    border-radius: 5px;
    background-color: rgb(24, 24, 27);
    width: 80%;
    height: 90vh;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1vh;
  }
  
  .form-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin: auto;
    padding: 1vh;
  }
  
  .button {
    margin-top: 10px;
    align-self: flex-start;
  }

  .the-form {
    width: 100%;
  }

