/* src/DebtPortalCustomerAccountFiltersBar.css */

/* Ensure the dropdown menu doesn't overflow and is scrollable */
.filter-dropdown .dropdown-menu {
    max-height: 300px; /* Adjust as needed */
    overflow-y: auto;
  }
  
  /* Optional: Add some spacing and alignment */
  .filters-bar {
    /* Add any additional styles if necessary */
  }
  
  .filters-bar .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Style for the selected items count in dropdown */
  .dropdown-toggle::after {
    margin-left: 0.5em;
  }