.calculative-summary-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
}



.tables-section-calc-sum {
    width: 90%;
}

.slabs-section {
    margin-top: 40px !important;
}

.projected-slabs-section {
    margin-top: 40px !important;
}