.homebar-main {
    display: flex;
    width: 100%;
    background-color: rgb(13, 13, 12);
    min-height: 30px;
    color: rgb(243, 227, 54);
    overflow: hidden;
}

.menu-icon {
    color: rgb(243, 227, 54);
    font-size: x-large;
    font-weight: 800;
}

.left-bar-section {
    display: flex;
    align-items: center;
    padding: 1vh;
}
.right-bar-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1vh;
    margin-left: auto;
    position: relative;
}

.right-bar-section :hover{
    background-color: rgb(143, 134, 30);
    color: black !important;
    cursor: pointer;
}
.custom-button-gold {
    color:  white !important;
    font-size: small;
    font-weight: 900;
}

.custom-button-gold:hover {
    background-color: rgb(143, 134, 30) !important;
    color: black !important;
    font-weight: 900;
    cursor: pointer;
}

.login-text {
    font-size: small;
    margin-right: 10px;
}

.homebar-logo {
    width: 80px;
    margin-left: 1vw;
}

@media screen and (max-width: 734px) {
    .homebar-logo {
        width: 28vw !important;
        height: 4vh !important;
        margin-left: 2vh !important;
        margin-right: 1vh !important;
    }
    
}