.nav-bar-holder {
    display: flex;
    width: 100%;
    align-items: center;
}

.planIt-navbar-right {
    display: flex;
    margin-left: auto;
    margin-right: 20px;
}

/* Keep the navbar full width, but use 100% rather than 100vw 
   so it doesn't overflow on mobile browsers */
   .planIt-navbar {
    width: 100%;
  }
  
  /* The parent .nav-bar-holder is replaced by Bootstrap container + d-flex classes,
     but if you still need it, ensure it doesn't conflict with the new layout. */
  
  /* Example media query to tweak layout if needed on extra small screens */
  @media (max-width: 576px) {
    .filters-row {
      /* Let the filters-row stack on small screens instead of 
         forcing them all in one line */
      flex-wrap: wrap !important;
    }
  }
  