.planning-table-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px; /* Prevents content from being hidden under pagination */
    font-size: 15px;
    width: 96%;
    margin: auto;
    max-height: 70vh;
  }
  
  .table-wrapper {
    overflow-y: scroll;
    border-top: 2px solid #333;

  }
  
  /* Sticky Table Header */
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #282c34; /* Dark header */
    font-size: 13px;
  }

  .selected-row {
    background-color: rgba(0, 123, 255, 0.2) !important;
  }
  
  /* Sticky Pagination Footer */
  .pagination-controls-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #121212;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top: 2px solid #333;
  }
  
  /* Pagination Top Control */
  .pagination-controls-top {
    display: flex;
    align-items: center; /* Align elements vertically */
    justify-content: flex-start;
    padding: 5px 10px; /* Reduce padding */
    font-size: 12px; /* Slightly smaller text */
  }

  /* Adjust form label */
  .pagination-controls-top .form-label {
    margin-right: 6px; /* Add spacing between label and select */
    font-size: 12px; /* Reduce font size */
    font-weight: 500;
    white-space: nowrap; /* Prevent wrapping */
  }

  /* Adjust select dropdown */
  .pagination-controls-top .form-select {
    font-size: 12px; /* Reduce text size */
    padding: 2px 6px; /* Reduce padding */
    height: 28px; /* Reduce height */
    width: auto; /* Allow select to resize */
    min-width: 70px; /* Prevent excessive shrinking */
    line-height: 1.2;
  }

  
  /* Scrollable Table Body */
  .table-body {
    max-height: 60vh;
    overflow-y: auto;
    font-size: 13px;

  }
  