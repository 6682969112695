/*CUBO ANALYTICS MAIN*/
.excel-dash-container-analytics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
    padding: 1;
    width: 100%;
}


.Analytics-cards-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px;
    padding: 1;
    width: 85%;
}

.font-blue {
    color: skyblue;
}

.font-springgreen {
    color: rgb(105, 239, 172);
}

.font-lemonchiffon {
    color: rgb(244, 191, 154);
}

/*Cards General*/ 
.small-card-text{
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
    justify-content: center;
}

.medium-card-text{
    font-size: 15px;
    margin: 0;
}

/*turnover Bar*/

.turnover-body {
    border-radius: 10px;
    padding: 3px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: auto;
    width: 85%;
    color: rgb(243, 227, 54);
}

.turnover-main-card {
    width: 95% !important;
}

.turnover-container {
    display: flex;
    flex-direction: row;
    align-content: baseline;
    justify-content: center;
}

.turnover-container-left {
    display: flex;
    flex-direction: row;
    align-content: baseline;
    width: 75%;
    justify-content: center;
}

.font-size-medium {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 10px;
}


/*CUBO CARD*/
.cubo-card-sales-data {
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    width: fit-content;
}

.cubo-card-sales-data-text-label {
    font-size: 12px;
}

.cubo-card-sales-data-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.line-between {
    border-right: 2px solid #ccc;
    width: 1px;
    height: 40px;
    margin-left: 15px;
    margin-right: 15px;
}

.line-between-2 {
    border-right: 3px solid rgb(243, 227, 54);
    width: 3px;
    height: 20px;
    margin-left: 15px;
    margin-right: 15px;
}

.revenue-result {
    font-weight: 500;
    font-size: 1.2rem;
}

/*CUBO CARD TRANSPORT*/
.cubo-card-body-transport {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    width: fit-content;
}

.cubo-card-transport-data-text {
    display: flex;
    flex-grow: 1;
    font-size: 15px;
}
.cubo-card-transport-data-text-label {
    flex-grow: 1
}

.custom-col {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1; /* Allow columns to grow and fill the available space */
    padding: 10px;
}

.bord-right {
    border-right: 1px solid #6e6c6c;
}

.bord-bottom {
    border-bottom: 1px solid #6e6c6c;
}

/*Chart Cpt*/
.-dynamic-chart-cpt- {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 1px;
    width: 80vw;
    height: 40vh;
}


.dynamic-chart-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 1px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.chart-viewer {
    display: flex;
    width: 85%;
    justify-content: center;
    align-items: center;
    border: 1px solid#1d1c1c;
    height: 100%;
}

.chart-selector {
    display: flex;
    flex-direction: column;
    width: 15%;
    justify-content: center;
    align-items: center;
    border: 1px solid #1d1c1c;
    height: 100%;
}

.button-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #1d1c1c;
    height: 100%;
}

.chart-selector-button {
    width: 80%;
    margin-top: 20px;
    font-weight: 900;
    font-size: medium;
}

.cubo-card-body-accounts {

}