.-Ventas-By-Client-Parent- {
    display: flex;
    flex-direction: row;
    height: 87vh;
}

.VBC-Client-Selection-Bar {
    display: flex;
    flex-direction: column;
    width: 15vw;
    border: 1px solid white;
    margin-top: 11px;
}

.VBC-Client-Selection-Bar-Button-Holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1px;
    overflow-y: scroll;
}

.client-selection-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: .5vw;
    margin-right: .5vw;
}

.VBC-Client-Selection-Button {
    font-size: small;
    padding: 2px;
    margin: 2px;
    border: 1px solid black;
}

.VBC-Client-Selection-Button:hover {
    background-color: #ff8c00;
    color: white;
}

.VBC-Client-Selection-Button.selected {
    background-color: #ffbb00;
    color: white;
}

.client-selection-button-text {
    width: 100%;
}

.-VBC-Main-Display-Area- {
    height: 86h;
    flex-grow: 1;
}

.VBC-Main-Display-Area-Sub-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.VBC_FiltersBar-Main-Card {
    width: 96%;
    font-size: small;
    background-color: #2c2c2c;
    color: #fff;
    border-radius: 10px;
    padding: 1px;
    margin-top: 8px;
    box-shadow: 0 0 10px #000;
}

/* SalesBrowser.css */
.table {
    cursor: pointer;
}
.SalesBrowser table tr:hover {
    background-color: #495057;
}

.sales-browser-body {
    display: flex;
    height: 61%;
}

.sales-browser-card {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 82%;
}

.sales-browser-table-body {
    overflow-y: scroll;
}

.sales-browser-sticky-row {
    position: sticky;
    top: -1px;
    background-color: #495057;
    color: white;
}

.sales-browser-table-holder {
    display: flex;
    height: 100%;
    overflow: auto;
}

.VBC-SalesBrowser-Decor-Selection-Bar-Button-Holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1px;
    overflow-y: scroll;
}

.VBC-SalesBrowser-Decor-Selection-Button {
    width: 95%;
    margin: auto;
    margin: 1px;
    font-size: small;
}

.VBC-SalesBrowser-Decor-Selection-Bar {
    display: flex;
    flex-direction: column;
    width: 12vw;
    border: 1px solid white;
}

.decor-selection-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: .5vw;
    margin-right: .5vw;
}

.top-materials-card {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 80%;
}

.top-materials-body {
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 90%;
    justify-content: center;
}

.top-materials-table {
    font-size: small;
}

.top-material-table-row {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    border-bottom: 1px solid #495057;
    height: 30vh;
    overflow-y: scroll;
}

.top-material-chart-row {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    border-bottom: 1px solid #495057;
    height: 30vh;
    justify-content: center;
    margin-top: 10px;
}

.dark-card-header-tooltip {
    display: flex;
    width: 100%;
}

.toggle-tooltip-label {
    position: relative;
}

.options-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #343a40; /* Dark navbar-like background */
    padding: 2px 2px;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .options-switch {
    color: white;
    font-size: 0.875rem; /* Slightly smaller font */
    display: flex;
    justify-content: center;
  }
  
  .options-switch label {
    position: relative;
    color: #f8f9fa !important; /* Ensures label remains white */
    margin-left: 15px;
    top: 2px;
  }
  
  .options-navbar:hover {
    background-color: #23272b; /* Slightly darker on hover */
  }