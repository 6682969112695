.purchase-orders-table {
    width: 80%;
}

.purchase-orders-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.purchase-orders-table {
    font-size: small;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    margin-top: 20px;
}

.po-header-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-top: 20px;
}