.sidemenu {
    left: -200px;
    width: 200px;
    min-height: 96vh;
    background-color: #131313;
    color: rgb(243, 227, 54);
    margin-bottom: 20px;
    z-index: 3;
    position: absolute;
    transition: left 0.4s ease; /* Add transition for the left property */
    /* Add additional styles as per your design */
  }

  .sidemenu-title{
    margin-top: 5px;
    position: relative;
    font-size: medium;
  }

  .hr-sidemenu {
    margin: 0 0 20px 0 !important;
  }

  .sidemenu-button {
    width: 95%;
    background-color:  #424141 !important;
    margin: 2px;
    border-color: #484848 !important;
    transition: background-color 0.3s !important;
    color: silver;
    font-size: small;
  }
  
  .sidemenu-button:hover {
    background-color: #909090 !important;
    border-color: #ccc !important;
    color: rgb(243, 227, 54);
  }

  .sidemenu-buttonicon {
    position: relative;
    margin-right: 20px;
  }

  .button-spacer {
    display: flex;
    align-items: center;
  }

  .icon-holder {
    height: 25px;
    width: 35px;
  }

  .sidemenu-title {
    margin-top: 5px;
    position: relative;
    font-size: medium;
  }
  
  .hr-sidemenu {
    margin: 0
  }  