.profile-img {
    margin-right: 1vw;
    border-radius: 50px;
    width: 50px;
}

.profile-text {
    margin-right: .9vw;
    font-size: small;
}

.profile {
    display: flex;
    align-items: center;
    flex-shrink: auto;
}