.file-input-box {
    width: 80%;
    margin: auto;
    display: block;
    margin-top: 10vh;
    font-size: medium !important;
    background-color: #242428;
    color: rgb(243, 227, 54);
}

.plan-it-go-button {
    margin-top: 1vh;
}

.save-data-check-plan-it {
    margin-top: 20px;
}