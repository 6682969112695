.placeholder-row-fullheight {
    height: 85vh;
    display: flex;
    border: 1px solid rgba(243, 227, 54, 0.272);
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
}

.insight-menu {
    max-width: 200px;
    border-right: 1px solid rgba(243, 227, 54, 0.272);
}

.insight-page {
    flex-grow: 1;
    margin: auto;
}

/* INSIGHT_STOCK */
.cards-holder-sub {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
}

.overflowY {
    overflow-y: scroll;
}