.discount-entry-parent {
    display: flex;
    font-size: small;
}

.customer-header {
    display: flex;
    margin-top: 2vh;
}

.discounts-child-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #d0c222;
    border-radius: 10px;
}

.materials-row {
    width: 80%;
}

.discounts-filebox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
}

.discount-frm-grp {
    font-size: medium;
}
