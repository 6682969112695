.customer-account-side-menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    width: 16vw;
    border: 1px solid #232323;
    height: 85vh;
}

.button-holder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    flex-direction: column;
    margin-top: 5px;
    overflow-y: scroll;
    
}

.button-item {
    display: flex;
}

.header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    flex-direction: column;
    margin-top: 10px;
}