#nav-collapse-margin {
    margin-left: 20px !important; 
}

.nav-collapse-margin {
    margin-left: 20px !important; 
}

/* Your custom stylesheet or style block */
.navbar-collapse {
    flex-basis: auto !important; /* Set to 'auto' or your desired value */
    flex-grow: 0 !important;
    align-items: stretch !important; /* Or any other value you need */
  }

.selected-filters-text {
  font-size: small !important;
  color: gainsboro !important;
}

.dropdown-menu {
  width: fit-content;
  max-height: 500px;
  overflow-y: scroll;
}

.drp-function-bar {
  align-items: center;
  padding: 5px;
  width: fit-content;
}

.plant-bar {
  padding: 5px;
  display: flex;

  align-items: center;
  justify-content: center;
  width: fit-content;
  width: 19%;
  margin: auto;
}

.plant-text {
  font-size: large;
}

/* Add this to your ExcelDashNavBar.css file */