.documentor-window-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: scroll;
  padding: 10px;
}

.-array-documentor-main- {
  display: flex;
  overflow-y: scroll;
  width: 100%;
}