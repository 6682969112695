/* Container card for the grid */
.kpi-grid-card {
    width: 90%;
    font-size: small;
    background-color: #2c2c2c; /* Dark background for the entire grid */
    color: #fff; /* White text for readability */
    border-radius: 8px; /* Slightly rounded corners */
    padding: 15px; /* Reduced padding for a more compact look */
    margin-top: 20px; /* Space between cards */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

/* KPI grid layout */
.kpi-grid {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 15px; /* Slightly reduced space between items */
}

/* Individual KPI item column for the top row */
.kpi-item-col {
    flex: 1 1 30%; /* Adjust to fit three items per row */
}

/* Individual KPI items */
.kpi-item {
    text-align: center;
    padding: 10px; /* Compact padding */
    background-color: #3a3a3a; /* Default background color */
    border-radius: 8px; /* Rounded corners for individual cards */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    height: 100%; /* Ensures full height within the column */
    color: #fff;
    font-size: small; /* Smaller font size */
    cursor: pointer; /* Pointer cursor to indicate clickable items */
    transition: background-color 0.3s ease; /* Smooth transition */
}

/* Selected KPI card background */
.kpi-item.selected {
    background-color: #ffc40098; /* Highlight color for selected KPI */
}

/* Title of KPI items */
.kpi-item h5 {
    margin-bottom: 8px; /* Reduced margin for tighter layout */
    font-weight: 500; /* Medium weight for titles */
    font-size: 0.9rem; /* Smaller font size for titles */
}

/* Value text of KPI items */
.kpi-item p {
    font-size: 1rem; /* Slightly smaller font size for values */
    font-weight: 600; /* Bold text for emphasis */
    color: #d1d1d1; /* Light grey color for values */
    margin: 0; /* Remove default margin */
}

.kpi-grid-card {
    border: none; /* Remove default card border */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add a subtle shadow for depth */
}

.kpi-item {
    transition: transform 0.2s; /* Smooth transition for hover effect */
}

.kpi-item:hover {
    transform: scale(1.05); /* Slight scale on hover for interactivity */
}

.kpi-item.selected {
    border: 2px solid #ffc400; /* Highlight selected KPI */
}
