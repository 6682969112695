.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin: auto;
}

.spinner {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative; /* Add this line to enable positioning */
  animation: spin 1s linear infinite;
}

/* Center the icon within the spinner */
.spinner svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
