.flex-end {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-right: 25px;
}

.navbar-brand-cubo-analyser {
    font-size: 1.0rem;
    font-weight: 500;
    color: rgb(200, 188, 51);
    margin-left: 15px;
    padding: 0px;
}

.-CUBOAnalyser-navbar {
    padding: 0px;
}