.sales-browser-table tr {
    background-color: transparent !important;
}

.sales-browser-table tr.expanded-row {
    background-color: #343a40 !important;
}

.sales-browser-table tr.expanded-sub-row {
    background-color: #6c757d !important;
}

.sales-browser-table tr.sub-row-content {
    background-color: #495057 !important;
}

.expanded-table-head th {
    background-color: #ffbf11b1 !important;
}

.expanded-table-body td {
    background-color: #495057 !important;
}